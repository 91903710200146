import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Badge,
    Flex,
    Link,
    Text,
    Image,
    Button
} from "@chakra-ui/react";
import { BsFileEarmarkExcel, BsBagHeart } from "react-icons/bs";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import DiamondDetail from "../../../components/diamond-detail";
import { WebUrl } from "../../../routes";
import { Role, ApiKey, OrderStatus, _DIAMOND_SHAPE_OPTIONS, _DIAMOND_LAB_OPTIONS, HoldDiamondStatus } from "../../../common/constant";
import { showCustomDialog, showCustomInputDialog } from "../../../application/action/app_action";
import { numberWithCurrencyFormat, renderDiamondTypeBadge, stringIsNullOrEmpty, getLabCertificateTrackUrl } from "../../../common/util";
import moment from "moment";
import ConfirmDiamondModal from "../../../components/confirm-diamond-modal";
import ApiEngine from "../../../common/api-engine";

/// <summary>
/// Author: Saitama
/// </summary>
export default () => {
    var _dispatch = useDispatch();
    var _router = useRouter();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [apiUrl, setApiUrl] = useState(`${Routes._INVENTORY_HOLD_DIAMONDS}?status=${OrderStatus._PENDING}`);
    const [selectedDiamonds, setSelectedDiamonds] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [allDiamonds, setAllDiamonds] = useState([]);
    const [confirmDiamondModal, setConfirmDiamondModal] = useState(false);

    const _COLUMNS = [
        {
            id: 'checkbox',
            headerWidth: '1%',
            customHeader: (
                <div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
                    <input id="checkboxSelectAll" type="checkbox" onChange={() => setCheckAll(!checkAll)} checked={checkAll} />
                    <label htmlFor="checkboxSelectAll"></label>
                </div>
            ),
            Cell: ({ row }) => {
                return (
                    row.original.status == HoldDiamondStatus._PENDING ?
                        <div className="checkbox checkbox-css checkbox-inline inbox-checkbox">
                            <input
                                id={`checkbox${row.id}`}
                                type="checkbox"
                                onChange={() => updateSelectedDiamonds(row.original)}
                                checked={selectedDiamonds.find(t => t.id == row.original.id)} />
                            <label htmlFor={`checkbox${row.id}`}></label>
                        </div> : <></>
                )
            },
            width: 30,
            disableSortBy: true,
        },
        {
            Header: "REQUEST_NO",
            accessor: "requestNo",
            headerClassName: "col-align-center",
            Cell: ({ row }) => {
                return <Flex flexDir={"column"}>
                    <Text textAlign={'center'} fontWeight={'bold'} fontSize={'14px'}>{row.original.requestNo}</Text>
                    <Text textAlign={'center'} color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>{moment(row.original.updatedAt).format('YYYY-MM-DD h:mm:ss A')}</Text>
                </Flex>
            }
        },
        {
            Header: "DIAMOND_DETAILS",
            accessor: "label",
            Cell: ({ row }) => {
                let diamondImg = row.original.diamondMedias.find(item => item.mediaType === 'Image');

                return <Flex flexDirection={'row'} alignItems={'center'}>
                    {
                        diamondImg ?
                            <Image className="table-diamond-img" src={diamondImg.mediaUrl} />
                            : <Image className="table-diamond-img" src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == row.original.shapeId)?.defaultImg} />
                    }
                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                        <Flex alignItems={'center'}>
                            <Flex alignItems={'center'} mr='5px'>
                                {renderDiamondTypeBadge(row.original.typeId)}
                                <Text
                                    ml='3px'
                                    fontSize={'13px'}
                                >
                                    {row.original.stockNo}
                                </Text>
                            </Flex>
                            <Link
                                fontSize={'13px'}
                                color='gold.600'
                                href={getLabCertificateTrackUrl(row.original.labId, row.original.certificateId)} isExternal>
                                {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == row.original.labId)?.label)}-{row.original.certificateId}
                            </Link>
                        </Flex>
                        <Text fontWeight={'bold'} fontSize={'16px'}>{row.original.label}</Text>
                    </Flex>
                </Flex>
            }
        },
        {
            Header: "HOLD_TIME",
            accessor: "holdTime",
            Cell: ({ row }) => {
                return <Text>{row.original.holdTime} {t('HOURS')}</Text>
            }
        },
        {
            Header: "STATUS",
            Cell: ({ row }) => {
                let colorScheme = row.original.status == HoldDiamondStatus._APPROVED ? "green" :
                    row.original.status == HoldDiamondStatus._PENDING ? "orange" : "gray";
                let text = row.original.status == HoldDiamondStatus._APPROVED ? "APPROVED" :
                    row.original.status == HoldDiamondStatus._PENDING ? "PENDING" : "RELEASED";

                return <Badge variant='subtle' colorScheme={colorScheme}>{t(text)}</Badge>
            }
        },
        {
            Header: "TOTAL_PRICE",
            headerClassName: "col-align-right",
            Cell: ({ row }) => {
                return <Flex flexDir="column" alignItems={'flex-end'}>
                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(row.original.totalPrice)}</Text>
                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(row.original.markupPricePerCarat))}</Text>
                </Flex>
            }
        },
        {
            Header: "REMARK",
            headerClassName: "col-align-right",
            Cell: ({ row }) => {
                return <Text textAlign={'right'}>{stringIsNullOrEmpty(row.original.remark) ? "-" : row.original.remark}</Text>
            }
        },
    ];

    /// <summary>
    /// Author: CK
    /// </summary> 
    useEffect(() => {
        let diamonds = [];

        if (checkAll && allDiamonds.filter(i => i.status == HoldDiamondStatus._PENDING).length > 0) {
            diamonds = allDiamonds.filter(i => i.status == HoldDiamondStatus._PENDING).map(selected => selected);
        }

        setSelectedDiamonds(diamonds);
    }, [checkAll]);

    /// <summary>
    /// Author: CK
    /// </summary> 
    function updateSelectedDiamonds(diamond) {
        if (selectedDiamonds.filter(i => i.id == diamond.id && diamond.status == HoldDiamondStatus._PENDING).length) {
            setSelectedDiamonds(selectedDiamonds.filter(i => i.id != diamond.id && diamond.status == HoldDiamondStatus._PENDING));
        }
        else {
            setSelectedDiamonds([...selectedDiamonds, diamond]);
        }
    }

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const orderDiamonds = async (params) => {
        try {
            var responseJson = await ApiEngine.post(Routes._ORDER_DIAMONDS, { diamondIds: params });

            if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
                throw responseJson[ApiKey._API_MESSAGE_KEY];
            }

            _dispatch(
                showCustomDialog({
                    success: true,
                    content: responseJson[ApiKey._API_MESSAGE_KEY],
                    onConfirm: () => _router.navigate(WebUrl._PURCHASEMENT_CONFIRM_DIAMOND_LIST)
                })
            );
            setApiUrl(apiUrl + '&v=' + moment());
            setSelectedDiamonds([]);
            setCheckAll(false);
            setConfirmDiamondModal(false);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err,
                })
            );
        }
    };

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                {
                    selectedDiamonds.length > 0 &&
                    <Flex justifyContent={'flex-end'}>
                        <Button
                            isDisabled={selectedDiamonds.length <= 0}
                            colorScheme="green"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<BsBagHeart />}
                            onClick={() => {
                                setConfirmDiamondModal(true);
                            }}
                        >
                            {t('ORDER')}
                        </Button>
                    </Flex>
                }
                <CustomTable
                    columnsData={_COLUMNS}
                    apiUrl={apiUrl}
                    customFetchAction={(responseJson) => setAllDiamonds(responseJson[ApiKey._API_DATA_KEY])}
                    RowAccordionContent={DiamondDetail} />
                <ConfirmDiamondModal
                    modalVisible={confirmDiamondModal}
                    setModalVisible={setConfirmDiamondModal}
                    selectedDiamonds={selectedDiamonds}
                    orderDiamonds={orderDiamonds}
                    isFromHoldDiamond={true}
                />
            </Card>
        </Box>
    )
}