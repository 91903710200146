import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  useColorModeValue,
  DrawerOverlay,
  useDisclosure,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import Content from "../../components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { IoMenuOutline } from "react-icons/io5";

/// <summary>
/// Author: Saitama
/// </summary>
function Sidebar(props) {
  const routes = props.routes.filter(i => !i.isHidden);

  return (
    <Box className='sidebar-container' backgroundColor={useColorModeValue("brand.300", "navy.800")} display={{ sm: "none", xl: "block" }} position='fixed' minH='100%'>
      <Box
        transition={"0.2s linear"}
        h='100vh'
        minH='100%'
        overflowX='hidden'
        boxShadow={useColorModeValue(
          "14px 17px 40px 4px rgba(112, 144, 176, 0.08)",
          "unset"
        )}>
        {/* <Scrollbars
          className='sidebar-scrollbar'
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={renderView}> */}
          <Content routes={routes} />
        {/* </Scrollbars> */}
      </Box>
    </Box>
  );
}

/// <summary>
/// Author: Saitama
/// </summary>
export function SidebarResponsive(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const routes = props.routes.filter(i => !i.isHidden);

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' h='max-content' onClick={onOpen}>
        <Icon
          as={IoMenuOutline}
          color={useColorModeValue("black.400", "white")}
          my='auto'
          w='20px'
          h='20px'
          me='10px'
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={"left"}
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent className='sidebar-container' w='auto' maxW='285px' bg={useColorModeValue("brand.300", "navy.800")}>
          <DrawerCloseButton
            zIndex='3'
            onClose={onClose}
            _focus={{ boxShadow: "none" }}
            _hover={{ boxShadow: "none" }}
          />
          <DrawerBody maxW='285px' px='0rem' pb='0'>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
              <Content routes={routes} onClose={onClose} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
