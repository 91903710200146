import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Divider,
    AbsoluteCenter,
    Stack
} from "@chakra-ui/react";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FANCY_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    ApiKey,
    CommonStatus,
    JewellerProfileStatus
} from "../common/constant";
import moment from "moment";
import { createMultiPartFormBody, stringIsNullOrEmpty, isObjectEmpty, numberWithCurrencyFormat, isAdminPortal, getLabCertificateTrackUrl } from "../common/util";
import { showCustomDialog } from "../application/action/app_action";
import CustomMediaUploader from "components/custom-media-uploader";
import { RiDeleteBin6Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import DiamondMedia from './diamond-media';

/// <summary>
/// Author: Saitama
/// </summary>
const DiamondDetailModal = React.forwardRef((props, ref) => {
    const {
        uploadModal,
        setUploadModal,
        uploadDiamondMedia,
        setMediaToDelete,
        mediaToDelete,
        setUploadedMedia,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    const handleFileListChange = ({ fileList: newFileList }) => {
        setUploadedMedia(newFileList);
    };

    return (
        <Modal size={'5xl'} isOpen={!isObjectEmpty(uploadModal)} onClose={() => setUploadModal({})}>
            <ModalOverlay />
            <ModalContent
                containerProps={{
                    zIndex: '9999',
                }} >
                <ModalCloseButton />
                <ModalBody>
                    <Flex pt='25px'>
                        <Flex flex={1} flexDir={'column'}>
                            <DiamondMedia item={uploadModal} medias={uploadModal?.diamondMedias} />
                        </Flex>
                        <Flex marginX={2} />
                        <Flex flex={1} flexDir={'column'} className="price-detail-container">
                            <Flex>
                                <Link
                                    fontSize={'13px'}
                                    color='gold.600'
                                    mr='10px'
                                    href={getLabCertificateTrackUrl(uploadModal?.labId, uploadModal?.certificateId)} isExternal>
                                    {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == uploadModal?.labId)?.label)}-{uploadModal?.certificateId} <ExternalLinkIcon mx='2px' />
                                </Link>
                                <Text
                                    fontSize={'13px'}
                                >
                                    {t('STOCK_NO')}: {uploadModal?.stockNo}
                                </Text>
                            </Flex>
                            <Text mt='15px' mb='15px' fontWeight={700} fontSize={'18px'}>{uploadModal?.label}</Text>
                            <Flex mb={"10px"}>
                                <Flex>
                                    <Text color="secondaryGray.500" fontSize={'13px'} mr={1}>{t("LOCATION")}: </Text>
                                    <Text fontSize={'13px'} fontWeight={600} color={'brand.900'}>{t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == uploadModal?.locationId)?.label)}</Text>
                                </Flex>
                            </Flex>
                            <Flex>
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={700} fontSize={'16px'} mb={"15px"}>{t("SUPPLIER_PRICE")}</Text>
                                    <Text color='gold.600' mt='3px' mb='5px' fontWeight={700} fontSize={'24px'}>${numberWithCurrencyFormat(uploadModal?.price)}</Text>
                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'13px'}>$/CT {numberWithCurrencyFormat(parseFloat(uploadModal?.pricePerCarat))}</Text>
                                </Flex>
                                <Divider orientation='vertical' marginX={5} />
                                <Flex flexDir={'column'}>
                                    <Text fontWeight={700} fontSize={'16px'} mb={"15px"}>{t("OUR_PRICE")}</Text>
                                    <Text color='gold.600' mt='3px' mb='5px' fontWeight={700} fontSize={'24px'}>${numberWithCurrencyFormat(uploadModal?.totalPrice)}</Text>
                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'13px'}>$/CT {numberWithCurrencyFormat(parseFloat(uploadModal?.markupPricePerCarat))}</Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Box position='relative' padding='10'>
                        <Divider />
                        <AbsoluteCenter bg='white' px='4'>
                            {t('DESCRIPTION')}
                        </AbsoluteCenter>
                    </Box>
                    <TableContainer className="diamond-detail-container">
                        <Table variant='simple' textAlign={'center'}>
                            <Thead>
                                <Tr>
                                    <Th colSpan={4}>{t('DIAMOND_DETAILS')}</Th>
                                    <Th colSpan={4}>{t('PARAMETER_DETAILS')}</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr>
                                    <Td colSpan={2}>{t('STOCK_NO')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.stockNo}</Td>
                                    <Td colSpan={2}>{t('MEASUREMENT')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.measurements}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('SHAPE')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_SHAPE_OPTIONS.find(i => i.id == uploadModal?.shapeId)?.label)}</Td>
                                    <Td colSpan={2}>{t('TABLE')}%</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.table}%</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('CARAT')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{numberWithCurrencyFormat(uploadModal?.carat, 2, true)}</Td>
                                    <Td colSpan={2}>{t('DEPTH')}%</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.depth}%</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('COLOR')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{
                                        t(
                                            !uploadModal?.isFancyColor ? _DIAMOND_COLOR_OPTIONS.find(i => i.id == uploadModal?.colorId)?.label
                                                : _DIAMOND_FANCY_COLOR_OPTIONS.find(i => i.id == uploadModal?.fancyColorId)?.label
                                        )
                                    }</Td>
                                    <Td colSpan={2}>{t('CROWN')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{`${uploadModal?.crownHeight}%, ${uploadModal?.crownAngle}°`}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('CLARITY')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_CLARITY_OPTIONS.find(i => i.id == uploadModal?.clarityId)?.label)}</Td>
                                    <Td colSpan={2}>{t('PAVILION')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{`${uploadModal?.pavilionDepth}%, ${uploadModal?.pavilionAngle}°`}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('CUT')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == uploadModal?.cutFinishLevelId)?.label)}</Td>
                                    <Td colSpan={2}>{t('GIRDLE')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.girdle}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('POLISH')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == uploadModal?.polishFinishLevelId)?.label)}</Td>
                                    <Td colSpan={2}>{t('SHADE')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_TINGE_SHADE_OPTIONS.find(s => s.id == uploadModal?.tingeShadeId)?.label)}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('SYMMETRY')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_FINISH_LEVEL_OPTIONS.find(i => i.id == uploadModal?.symmetryFinishLevelId)?.label)}</Td>
                                    <Td colSpan={2}>{t('MILKY')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_TINGE_MILKY_OPTIONS.find(m => m.id == uploadModal?.tingeMilkyId)?.label)}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('FLUO')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_FLUORESCENSE_OPTIONS.find(i => i.id == uploadModal?.fluorescenceId)?.label)}</Td>
                                    <Td colSpan={2}>{t('EYE_CLEAN')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_TINGE_EYE_CLEAN_OPTIONS.find(e => e.id == uploadModal?.tingeEyeCleanId)?.label)}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('LAB')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_LAB_OPTIONS.find(i => i.id == uploadModal?.labId)?.label)}</Td>
                                    <Td colSpan={2}>{t('KEY_TO_SYMBOL')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.keyToSymbol}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('CERTIFICATE')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.certificateId}</Td>
                                    <Td colSpan={2}>{t('ORIGIN')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.origin}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}>{t('LOCATION')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == uploadModal?.locationId)?.label)}</Td>
                                    <Td colSpan={2}>{t('BRAND')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{uploadModal?.brand}</Td>
                                </Tr>
                                <Tr>
                                    <Td colSpan={2}></Td>
                                    <Td colSpan={2}></Td>
                                    <Td colSpan={2}>{t('TREATMENT')}</Td>
                                    <Td colSpan={2} fontWeight={'800'}>{t(_DIAMOND_TREATMENT_OPTIONS.find(l => l.id == uploadModal?.treatmentId)?.label)}</Td>
                                </Tr>
                            </Tbody>
                        </Table>
                    </TableContainer>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme='red' onClick={() => setUploadModal({})}>
                        {t('CLOSE')}
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
});

export default DiamondDetailModal;