import React from "react";

// Chakra imports
import { Flex, useColorModeValue, Heading, Icon, Image } from "@chakra-ui/react";
import { TbChevronsRight } from "react-icons/tb";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const brandColor = useColorModeValue("brand.800", "white");

  return (
    <Flex className="sidebar-logo-container" justifyContent={'center'} alignItems='center' direction='column'>
      {/* <Heading size="lg" style={{ fontStyle: 'italic' }}>Jewin</Heading>*/}
      <Image className="sidebar-logo" src={"/assets/logo.png"} />
      <Icon className="sidebar-right-icon" w='25px' h='25px' mt={'10px'} as={TbChevronsRight} color={brandColor} />
    </Flex>
  );
}

export default SidebarBrand;
