import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import {
    Box,
    Flex,
    Button,
    Text,
    Link,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Badge,
    VStack,
    Image,
    IconButton,
    Textarea
} from "@chakra-ui/react";
import {
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TREATMENT_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    InvoiceStatus
} from "../common/constant";
import { stringIsNullOrEmpty, getFileTypeFromUrl, numberWithCurrencyFormat, renderDiamondTypeBadge, getLabCertificateTrackUrl, renderInvoiceStatus } from "../common/util";
import { FaFileInvoiceDollar, FaCircleInfo, FaDownload } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { ExternalLinkIcon } from '@chakra-ui/icons'

/// <summary>
/// Author: CK
/// </summary>
const InvoiceDiamondDetail = React.forwardRef((props, ref) => {
    const {
        item,
        ...restProps
    } = props;
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [infoModal, setInfoModal] = useState(false);

    return (
        <Box className="diamond-detail-accordion" bgColor={'gray.100'} p={'15px'}>
            <Flex flexDir={'column'}>
                <Flex flexDir={'row'} justifyContent={'space-between'} mb={"10px"}>
                    <Flex flexDir="column" alignItems={'center'}>
                        {renderInvoiceStatus(item.status)}
                    </Flex>
                    <Flex gap={2}>
                        <Button
                            colorScheme="teal"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaCircleInfo />}
                            onClick={() => { setInfoModal(true); }}
                        >
                            {t('VIEW_INFO')}
                        </Button>
                        <Button
                            colorScheme="blue"
                            fontSize="sm"
                            fontWeight="500"
                            type="button"
                            ml="5px"
                            leftIcon={<FaFileInvoiceDollar />}
                            onClick={() => {
                                fetch(item.invoiceUrl, {
                                    method: 'GET',
                                    headers: {
                                        'Content-Type': 'application/octet-stream',
                                    },
                                })
                                    .then((response) => response.blob())
                                    .then((blob) => {
                                        const link = document.createElement('a');
                                        link.href = window.URL.createObjectURL(blob);
                                        link.download = "INV_" + item.invoiceNo
                                        document.body.appendChild(link);
                                        link.click();
                                        link.remove();
                                    })
                                    .catch((error) => console.error('Download error:', error));
                            }}
                        >
                            {t('VIEW_INVOICE')}
                        </Button>
                    </Flex>
                </Flex>
                <TableContainer width={'100%'}>
                    <Table variant='simple' className="hold-diamond-table">
                        <Thead backgroundColor={'gray.300'}>
                            <Tr>
                                <Th>{t("ORDER_NO")}</Th>
                                <Th>{t("DIAMOND_DETAILS")}</Th>
                                <Th>{t("LOCATION")}</Th>
                                <Th textAlign={'right'}>{t("TOTAL_PRICE")}<br />$/CT</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {
                                item.orders.map(order => {
                                    let diamondDetail = order.diamond != undefined ? order.diamond : order;
                                    let diamondImg = diamondDetail.diamondMedias.find(item => item.mediaType === 'Image');

                                    return (
                                        <Tr>
                                            <Td>
                                                <Text fontSize="15px">
                                                    {order.orderNo}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDirection={'row'} alignItems={'center'}>
                                                    {
                                                        diamondImg ?
                                                            <Image src={diamondImg.mediaUrl} />
                                                            : <Image src={_DIAMOND_SHAPE_OPTIONS.find(i => i.id == diamondDetail.shapeId)?.defaultImg} />
                                                    }
                                                    <Flex ml='10px' flexDir={'column'} alignItem='flex-start'>
                                                        <Flex alignItems={'center'}>
                                                            <Flex alignItems={'center'} mr='5px'>
                                                                {renderDiamondTypeBadge(diamondDetail.typeId)}
                                                                <Text
                                                                    ml='3px'
                                                                    fontSize={'13px'}
                                                                >
                                                                    {diamondDetail.stockNo}
                                                                </Text>
                                                            </Flex>
                                                            <Link
                                                                fontSize={'13px'}
                                                                color='gold.600'
                                                                href={getLabCertificateTrackUrl(diamondDetail.labId, diamondDetail.certificateId)} isExternal>
                                                                {t(_DIAMOND_LAB_OPTIONS.find(i => i.id == diamondDetail.labId)?.label)}-{diamondDetail.certificateId}
                                                            </Link>
                                                        </Flex>
                                                        <Text fontWeight={'bold'} fontSize={'16px'}>{diamondDetail.label}</Text>
                                                    </Flex>
                                                </Flex>
                                            </Td>
                                            <Td>
                                                <Text fontSize="16px">
                                                    {t(_DIAMOND_LOCATION_OPTIONS.find(l => l.id == diamondDetail.locationId)?.label)}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Flex flexDir="column" alignItems={'flex-end'}>
                                                    <Text fontWeight={700} fontSize={'16px'}>${numberWithCurrencyFormat(order.totalPrice)}</Text>
                                                    <Text color="secondaryGray.500" fontWeight={500} fontSize={'12px'}>$/CT {numberWithCurrencyFormat(parseFloat(order.markupPricePerCarat ?? order.pricePerCarat))}</Text>
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    )
                                })
                            }
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>
            <Modal size={'5xl'} isOpen={infoModal} onClose={() => { setInfoModal(false) }}>
                <ModalOverlay />
                <ModalContent
                    containerProps={{
                        zIndex: '99999',
                    }} >
                    <ModalHeader>{`${t('INVOICE')} (${item?.invoiceNo})`}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <TableContainer className="supplier-detail-table" mb={5}>
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('BASIC_INFORMATION')}</Th>
                                        <Th colSpan={2} />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('PERSON_IN_CHARGE')}</Td>
                                        <Td>{item?.personInCharge}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('TELEPHONE')}</Td>
                                        <Td>{item?.phoneNumber}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('EMAIL')}</Td>
                                        <Td>{item?.email}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('COMPANY_TAX_ID')}</Td>
                                        <Td>{item?.shipToCompanyTaxId}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('PORT_OF_DISCHARGE')}</Td>
                                        <Td>{item?.shipToPortOfDischarge}</Td>
                                        <Td />
                                        <Td />
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table" mb={5}>
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('SHIPPING_INFO')}</Th>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('BILLING_INFO')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_COMPANY_NAME')}</Td>
                                        <Td>{item?.shipToCompanyName}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_COMPANY_NAME')}</Td>
                                        <Td>{item?.billToCompanyName}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_ADDRESS')}</Td>
                                        <Td>{item?.shipToAddress}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_ADDRESS')}</Td>
                                        <Td>{item?.billToAddress}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_ZIPCODE')}</Td>
                                        <Td>{item?.shipToZipCode}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_ZIPCODE')}</Td>
                                        <Td>{item?.billToZipCode}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_CITY')}</Td>
                                        <Td>{item?.shipToCity}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_CITY')}</Td>
                                        <Td>{item?.billToCity}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_STATE')}</Td>
                                        <Td>{item?.shipToState}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_STATE')}</Td>
                                        <Td>{item?.billToState}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_COUNTRY')}</Td>
                                        <Td>{item?.shipToCountry}</Td>
                                        <Td fontWeight={'800'}>{t('BILLING_COUNTRY')}</Td>
                                        <Td>{item?.billToCountry}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_TELEPHONE')}</Td>
                                        <Td>{item?.shipToPhoneNumber}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('SHIPPING_EMAIL')}</Td>
                                        <Td>{item?.shipToEmail}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <TableContainer className="supplier-detail-table">
                            <Table variant='simple' textAlign={'center'}>
                                <Thead>
                                    <Tr>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('PAYMENT_INFO')}</Th>
                                        <Th colSpan={2} fontWeight={'900'} fontSize={'larger'}>{t('TRACKING_INFO')}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BANK_NAME')}</Td>
                                        <Td>{item?.bankName}</Td>
                                        <Td fontWeight={'800'}>{t('LOGISTIC_NAME')}</Td>
                                        <Td>{item?.logisticName}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BANK_ACC_NAME')}</Td>
                                        <Td>{item?.bankAccountName}</Td>
                                        <Td fontWeight={'800'}>{t('LOGISTIC_TRACK_NO')}</Td>
                                        <Td>{item?.logisticTrackNo}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BANK_ACC_NO')}</Td>
                                        <Td>{item?.bankAccountNo}</Td>
                                        <Td fontWeight={'800'}>{t('LOGISTIC_TRACK_URL')}</Td>
                                        <Td>
                                            {
                                                !stringIsNullOrEmpty(item?.logisticTrackUrl) ?
                                                    <Link
                                                        href={item?.logisticTrackUrl} isExternal>
                                                        {item?.logisticName} <ExternalLinkIcon mx='2px' />
                                                    </Link> :
                                                    <Text>-</Text>
                                            }
                                        </Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BANK_REF_NO')}</Td>
                                        <Td>{item?.bankReferenceNo}</Td>
                                        <Td fontWeight={'800'}>{t('ESTIMATED_SHIPPING_DAY')}</Td>
                                        <Td>{item?.estimatedShippingDay}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td fontWeight={'800'}>{t('BANK_RECEIPT')}</Td>
                                        <Td>
                                            <Box position="relative" w="75px" h="75px" overflow="hidden" role="group">
                                                <Image
                                                    boxSize={'75px'}
                                                    objectFit={'cover'}
                                                    src={!stringIsNullOrEmpty(item?.bankReceiptAttachment) ? getFileTypeFromUrl(item?.bankReceiptAttachment) === 'PDF' ? require('../assets/img/pdf.png') : item?.bankReceiptAttachment : require('../assets/img/no_image.png')}
                                                />
                                                {
                                                    !stringIsNullOrEmpty(item?.bankReceiptAttachment) &&
                                                    <IconButton
                                                        position="absolute"
                                                        top="50%"
                                                        left="50%"
                                                        transform="translate(-50%, -50%)"
                                                        opacity="0"
                                                        transition="opacity 0.3s ease"
                                                        _groupHover={{ opacity: 1 }}
                                                        icon={<FaDownload />}
                                                        onClick={() => {
                                                            fetch(item.bankReceiptAttachment, {
                                                                method: 'GET',
                                                                headers: {
                                                                    'Content-Type': 'application/octet-stream',
                                                                },
                                                            })
                                                                .then((response) => response.blob())
                                                                .then((blob) => {
                                                                    const link = document.createElement('a');
                                                                    link.href = window.URL.createObjectURL(blob);
                                                                    link.download = `${item?.invoiceNo}_BANK_RECEIPT`
                                                                    document.body.appendChild(link);
                                                                    link.click();
                                                                    link.remove();
                                                                })
                                                                .catch((error) => console.error('Download error:', error));
                                                        }} />
                                                }
                                            </Box>
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        </TableContainer>
                        <VStack>
                            <Text fontWeight={'800'}>{t('REMARK')}</Text>
                            <Textarea isDisabled value={item?.remark} placeholder={t('EMPTY_REMARK')} />
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
});

export default InvoiceDiamondDetail;