import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useRouter } from "../../common/useRouter";
import {
    Box,
    Button,
    Flex,
    FormControl,
    Heading,
    Text,
    Image,
    Divider,
    Container,
    Link
} from "@chakra-ui/react";
import DefaultAuth from "../../layouts/non-auth/Default";
import illustration from "assets/img/auth/auth.jpg";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import CustomInput from "../../components/custom-input";
import CustomPhoneInput from "../../components/custom-phone-input";
import { performLogin } from "../../application/action/auth_action";
import { RegexPattern, ApiKey, Role } from "../../common/constant";
import { WebUrl } from "../../routes";
import { showCustomDialog } from "../../application/action/app_action";
import ApiEngine from "../../common/api-engine";
import Routes from "../../common/api_routes";

/// <summary>
/// Author: CK
/// </summary>
function Home() {
    var _router = useRouter();
    const { t } = useTranslation();
    const _dispatch = useDispatch();

    return (
        <div className="landing-page">
            {/* <Flex justifyContent={'center'} alignItems={'center'} className="landing-header">
                <Image src={"/assets/header1.png"} />
                <Divider orientation='vertical' />
                <Image src={"/assets/header2.png"} />
                <Divider orientation='vertical' />
                <Image src={"/assets/header3.png"} />
                <Divider orientation='vertical' />
                <Image src={"/assets/header4.png"} />
                <Divider orientation='vertical' />
                <Image src={"/assets/header5.png"} />
            </Flex> */}
            <Flex alignItems={'center'} flexDir={'column'} className="landing-banner-container">
                <Image className="landing-page-logo" src={"/assets/logo.png"} />
                <Flex alignItems={'center'} flexDir={'column'} className="landing-banner-headings">
                    <Heading as='h1' color="white" className="banner-title">
                        BUY
                    </Heading>
                    <Heading as='h1' color="white" className="banner-big-title">
                        DIAMONDS
                    </Heading>
                    <Heading as='h1' color="white" className="banner-title">
                        ONLINE
                    </Heading>
                </Flex>
                <Text className="banner-description" color="white">FROM LIVE INVENTORY ON SINGLE CLICK</Text>
                <Flex className="landing-banner-absolute">
                    <Image src={"/assets/banner1.png"} />
                    <Button className="pulse signup-btn" onClick={() => { _router.navigate(WebUrl._REGISTER); }}>JOIN NOW! IT'S FREE</Button>
                </Flex>
            </Flex>
            <Container className='about-us-container'>
                <Flex alignItems={'center'} flexDir={'column'}>
                    <Divider className='about-us-divider' orientation='vertical' />
                    <Heading as='h1' className="content-title" textAlign='center'>
                        WHY CHOOSE US
                    </Heading>
                    <Text className="content-description" textAlign={'center'}>Here are a few key reasons why Jewin Diamonds simplifies buying diamonds online.</Text>
                    <Image className='about-us1-img' src={"/assets/about-us1.png"} />
                </Flex>
            </Container>
            <Container className='diamond-feed-container'>
                <Flex>
                    <Flex className='diamond-feed-col-1' flex={1} alignItems={'flex-end'} flexDir={'column'}>
                        <Heading as='h1' className="content-title" textAlign={'right'}>
                            DIAMOND <br />FEED
                        </Heading>
                        <Image className='curve-img' src={"/assets/curve.png"} />
                    </Flex>
                    <Flex flex={1} alignItems={'flex-start'} flexDir={'column'}>
                        <Text className="content-description diamond-feed-description">
                            Showcase natural & lab-grown diamond inventory directly on your website. API features live feed directly from manufacturers, with the flexibility to choose and filter as per your customer′s need. It provides freedom of automation to grow your business 24x7.
                        </Text>
                    </Flex>
                </Flex>
            </Container>
            <Container className='benefit-container'>
                <Flex justifyContent={'center'}>
                    <Flex className="benefit-col-1" flexDir={'column'}>
                        <Box className="benefit-item" >
                            <Image className='benefit-img' src={"/assets/benefit1.png"} />
                            <Text className="content-description benefit-title">Live Inventory</Text>
                            <Text className="content-description benefit-description">Access an inventory of 60,000+ natural and 150,000+ lab-grown diamonds.</Text>
                        </Box>
                        <Box className="benefit-item" >
                            <Image className='benefit-img' src={"/assets/benefit2.png"} />
                            <Text className="content-description benefit-title">Save Time & Increase Profit</Text>
                            <Text className="content-description benefit-description">With easy purchase, consolidated shipment & single payment save time & increase profit on every order.</Text>
                        </Box>
                    </Flex>
                    <Flex className="benefit-col-2" flexDir={'column'}>
                        <Box className="benefit-item" >
                            <Image className='benefit-img' src={"/assets/benefit3.png"} />
                            <Text className="content-description benefit-title">Instant Buy</Text>
                            <Text className="content-description benefit-description">Directly buy a diamond on the website or using API, we will take care of everything from quality check to consolidated delivery.</Text>
                        </Box>
                        <Box className="benefit-item" >
                            <Image className='benefit-img' src={"/assets/benefit4.png"} />
                            <Text className="content-description benefit-title">24x7 Customer Support</Text>
                            <Text className="content-description benefit-description">With hotline and live chat, we are always up and running. We can answer all your queries instantly in all time zones.</Text>
                        </Box>
                    </Flex>
                </Flex>
            </Container>
            <Container className='our-product-container'>
                <Flex flexDir='column' justifyContent={'center'}>
                    <Heading as='h1' className="content-title" textAlign={'center'}>
                        OUR PRODUCTS
                    </Heading>
                    <Text className="content-description" textAlign={'center'} maxW='800px' marginX={'auto'}>We provide round and fancy shape diamonds certified by trusted diamond grading
                        organisations such as GIA, HRD, IGI and AGS.</Text>
                    <Flex flexDir='row' justifyContent={'space-evenly'} mt='35px'>
                        <Flex className="our-product-item" justifyContent={'flex-start'} alignItems={'center'} flexDir={'column'}>
                            <Image src={"/assets/natural-diamond.jpg"} />
                            <Text className="content-description our-product-title">Natural Diamonds</Text>
                            <Text className="content-description our-product-description" textAlign={'center'}>Diamonds with color from D to Z. Diamonds with colors like pink, blue, green etc.</Text>
                        </Flex>
                        <Flex className="our-product-item" justifyContent={'flex-start'} alignItems={'center'} flexDir={'column'}>
                            <Image src={"/assets/lab-grown-diamond.jpg"} />
                            <Text className="content-description our-product-title">Lab-grown Diamonds</Text>
                            <Text className="content-description our-product-description" textAlign={'center'}>Diamonds Grown in Lab with color form D to Z. Diamonds Grown in lab with colors like yellow, pink, blue, green etc.</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
            <Flex className='step-container'>
                <Container>
                    <Flex flexDir='column' justifyContent={'center'}>
                        <Heading as='h1' className="content-title" textAlign={'center'}>
                            HOW IT WORKS
                        </Heading>
                        <Text className="content-description" textAlign={'center'} maxW='800px' marginX={'auto'}>Buy Diamonds in 3 easy Steps</Text>
                        <Flex flexDir='column' mt='50px'>
                            <Flex className="step-item" justifyContent={'center'} alignItems={'center'} flexDir={'row'}>
                                <Flex flex={1} flexDir='column' alignItems={'center'} justifyContent={'center'}>
                                    <Image flex={1} src={"/assets/step1.png"} />
                                </Flex>
                                <Flex position={'relative'} flex={1} flexDir='column' alignItems={'flex-start'} justifyContent={'center'}>
                                    <Text className="step-index step-index-1">1</Text>
                                    <Text className="content-description step-title">Buy Diamonds</Text>
                                    <Text className="content-description step-description">
                                        Search & select diamonds, with detailed parameters, from our live inventory of 60,000+ natural and 150,000+ lab-grown diamonds.
                                    </Text>
                                </Flex>
                            </Flex>
                            <Flex className="step-item" justifyContent={'center'} alignItems={'center'} flexDir={'row'}>
                                <Flex position={'relative'} flex={1} flexDir='column' alignItems={'flex-start'} justifyContent={'center'}>
                                    <Text className="step-index step-index-2">2</Text>
                                    <Text className="content-description step-title step-title-2">Consolidate Orders</Text>
                                    <Text className="content-description step-description step-description-2">
                                        The ordered diamonds are quality-checked and consolidated under a single shipment as per preference, irrespective of the source location of the diamond you choose.
                                    </Text>
                                </Flex>
                                <Flex flex={1} flexDir='column' alignItems={'center'} justifyContent={'center'}>
                                    <Image flex={1} src={"/assets/step2.png"} />
                                </Flex>
                            </Flex>
                            <Flex className="step-item last" justifyContent={'center'} alignItems={'center'} flexDir={'row'}>
                                <Flex flex={1} flexDir='column' alignItems={'center'} justifyContent={'center'}>
                                    <Image className="step-item-last-img" flex={1} src={"/assets/step3.png"} />
                                </Flex>
                                <Flex position={'relative'} flex={1} flexDir='column' alignItems={'flex-start'} justifyContent={'center'}>
                                    <Text className="step-index step-index-3">3</Text>
                                    <Text className="content-description step-title">Get Delivered</Text>
                                    <Text className="content-description step-description">
                                        We are partnered with the fastest logistics that deliver your order as fast as the next day, hassle-free.
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
            <Container className='partner-container'>
                <Flex flexDir='column' justifyContent={'center'}>
                    <Heading as='h1' className="content-title" textAlign={'center'}>
                        BENEFITS OF PARTNERING WITH US
                    </Heading>
                    <Text className="content-description" textAlign={'center'} maxW='800px' marginX={'auto'}>
                        Partnering with us help you grow your Jewelery business with ease. <br />
                        You can focus on catering your customers and we will always have your back.
                    </Text>
                    <Flex flexDir='row' justifyContent={'space-evenly'} mt='35px'>
                        <Flex className="partner-item" justifyContent={'flex-start'} alignItems={'center'} flexDir={'column'}>
                            <Image src={"/assets/partner1.png"} />
                            <Text className="content-description partner-title" textAlign={'center'}>No Hidden Cost</Text>
                            <Text className="content-description partner-description" textAlign={'center'}>No signup fee, No extra charge. Pay what you see.</Text>
                        </Flex>
                        <Flex className="partner-item" justifyContent={'flex-start'} alignItems={'center'} flexDir={'column'}>
                            <Image src={"/assets/partner2.png"} />
                            <Text className="content-description partner-title" textAlign={'center'}>Quality Check</Text>
                            <Text className="content-description partner-description" textAlign={'center'}>Ensuring all the diamonds ordered meets global quality standards including Shade, lustre & eye clean.</Text>
                        </Flex>
                        <Flex className="partner-item" justifyContent={'flex-start'} alignItems={'center'} flexDir={'column'}>
                            <Image src={"/assets/partner3.png"} />
                            <Text className="content-description partner-title" textAlign={'center'}>One Stop Solution</Text>
                            <Text className="content-description partner-description" textAlign={'center'}>Buy, pay, ship and track, everything at one place.</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Container>
            <Container className='request-demo-container'>
                <Flex flexDir='row' justifyContent={'center'} alignItems={'center'}>
                    <Flex className='request-demo-item' flex={1} flexDir={'column'} alignItems={'flex-end'}>
                        <Image src={"/assets/request-demo.png"} />
                    </Flex>
                    <Flex className='request-demo-item' flex={1} flexDir={'column'}>
                        <Heading as='h1' className="content-title">
                            REQUEST <br />
                            FOR DEMO
                        </Heading>
                        <Text className="content-description request-deomo-description">
                            Start using Jewin Diamonds today
                            by booking a demo
                            with one of our experts.
                        </Text>
                        <Button className="pulse signup-btn" onClick={() => { _router.navigate(WebUrl._REGISTER); }}>JOIN NOW! IT'S FREE</Button>
                    </Flex>
                </Flex>
            </Container>
            {/* <Container className='blog-container'>
                <Heading as='h1' className="content-title" textAlign={'center'}>
                    LATEST FROM BLOG
                </Heading>
                <Flex flexDir='row' justifyContent={'space-evenly'} mt='35px'>
                    <Flex className="our-product-item" justifyContent={'flex-start'} flexDir={'column'}>
                        <Image src={"/assets/blog.png"} />
                        <Text className="content-description blog-title">Discover Diamonds | The Genesis of World . . .</Text>
                        <Text className="content-description blog-author">BY Author</Text>
                        <Text className="content-description blog-description">
                            How Diamonds Are Manufactured? While there is a plethora of innovative ways to reach a person's heart, Diamonds are undoubtedly the mos . . . <Link className="blog-link">Read more</Link>
                        </Text>
                    </Flex>
                    <Flex className="our-product-item" justifyContent={'flex-start'} flexDir={'column'}>
                        <Image src={"/assets/blog.png"} />
                        <Text className="content-description blog-title">Heart & Arrow Diamonds | Is it worth pay . . .</Text>
                        <Text className="content-description blog-author">BY Author</Text>
                        <Text className="content-description blog-description">
                            How amazing is it that a diamond ring is all it takes to express your love? It is a reflection of infinity that touches hearts like no ot . . . <Link className="blog-link">Read more</Link>
                        </Text>
                    </Flex>
                </Flex>
            </Container> */}
            <Container>
                <Flex alignItems="center" flexDir="column" className="mobile-app-title-container">
                    <Heading as='h1' className="content-title" textAlign={'center'}>
                        BUY DIAMONDS <br />
                        ON THE GO!
                    </Heading>
                    <Image className="mobile-app-img" src={"/assets/mobile-app.jpg"} />
                </Flex>
            </Container>
            <Flex className='mobile-app-container'>
                <Container>
                    <Flex className='mobile-app-item' justifyContent={'center'} alignItems={'center'}>
                        <Text flex={1} justifyContent={'center'} className="content-description">
                            Jewin Diamonds is available for all devices. <br />
                            Share diamond details, <br />
                            Track your orders and Get notified.
                        </Text>
                        <Flex flex={1} alignItems={'center'} flexDir="column">
                            <Image className="app-store-img" mb="5px" src={"/assets/mobile-google-play.png"} />
                            <Image className="app-store-img" src={"/assets/mobile-app-store.png"} />
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
            <Flex className='footer-container'>
                <Container>
                    <Flex className='mobile-app-item' justifyContent={'center'} alignItems={'center'}>
                        <Flex flex={1} alignItems={'center'}>
                            <Image className="landing-page-logo" src={"/assets/logo-white.png"} />
                            <Text flex={1} ml="15px" justifyContent={'center'} color="white" className="content-description">
                                Kuala Lumpur, Malaysia <br/>
                                info@jw-diamonds.com
                            </Text>
                        </Flex>
                    </Flex>
                </Container>
            </Flex>
        </div>
    );
}

export default Home;
