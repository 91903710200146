import React, { useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { getValidationMessage } from "../common/util";
import {
    Box,
    FormLabel,
    Badge,
    Menu,
    MenuButton,
    MenuList,
    Tooltip,
    Flex,
    Button,
    Image,
    Text
} from "@chakra-ui/react";

/// <summary>
/// Author: CK
/// </summary>
const CustomOptionSelect = React.forwardRef((props, ref) => {
    const {
        id,
        value,
        onChange,
        errors,
        patternErrorTxt,
        label,
        style,
        options = [],
        showEmptyOption = false,
        width,
        height,
        btnFontSize,
        selectedValue = [],
        description,
        disabledOuterWrap = false,
        ...restProps
    } = props;
    const { i18n, t } = useTranslation();

    const renderedItems = useMemo(() => {
        return options.map(option => {
            return <Tooltip label={t(option.label)}>
                <Button
                    mb={'15px'}
                    width={width ?? 'auto'}
                    height={height ?? 'auto'}
                    className={selectedValue.includes(option.value) && 'selected'}
                    onClick={() => {
                        if (onChange) {
                            if (selectedValue.includes(option.value)) {
                                onChange(selectedValue.filter((item) => item !== option.value));
                            } else {
                                onChange([...selectedValue, option.value]);
                            }
                        }
                    }}
                >
                    <Flex width={'100%'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                        {
                            option.image && <Image src={option.image} />
                        }
                        <Text
                            width={'100%'}
                            overflow={'auto'}
                            textOverflow={'ellipsis'}
                            fontSize={btnFontSize ?? '14px'}
                        >
                            {t(option.label)}
                        </Text>
                    </Flex>
                </Button>
            </Tooltip>
        })
    }, [options, selectedValue, i18n.language]);

    return (
        <Flex alignItems={'center'} flexWrap={!disabledOuterWrap ? 'wrap' : ''}>
            {
                label &&
                <Flex flexDirection={'column'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t(label)}</Text>
                    {description && <Text color={'brand.950'} textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'} fontSize={'13px'}>{t(description)}</Text>}
                </Flex>
            }
            <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} className="option-select" flexWrap={'wrap'}>
                {
                    renderedItems
                }
            </Flex>
        </Flex>
    )
});

export default CustomOptionSelect;