import React, { useEffect, useState } from "react";
import { useRouter } from "../../../common/useRouter";
import { useTranslation } from "react-i18next";
import {
    Box,
    Divider,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Flex,
    Button,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    Link,
    Text
} from "@chakra-ui/react";
import { FaGear, FaPen } from "react-icons/fa6";
import { RiDeleteBin6Line } from "react-icons/ri";
import Card from "components/card/Card";
import { useDispatch, useSelector } from "react-redux";
import Routes from "../../../common/api_routes";
import CustomTable from "../../../components/custom-table";
import { WebUrl } from "../../../routes";
import {
    Role,
    _DIAMOND_SHAPE_OPTIONS,
    _DIAMOND_CLARITY_OPTIONS,
    _DIAMOND_COLOR_OPTIONS,
    _DIAMOND_FINISH_LEVEL_OPTIONS,
    _DIAMOND_FLUORESCENSE_OPTIONS,
    _DIAMOND_LAB_OPTIONS,
    _DIAMOND_TYPE_OPTION,
    _DIAMOND_STOCK_STATUS_OPTIONS,
    DiamondColorType,
    DiamondType,
    _DIAMOND_AVAILABILITY_OPTIONS,
    _DIAMOND_TINGE_EYE_CLEAN_OPTIONS,
    _DIAMOND_TINGE_SHADE_OPTIONS,
    _DIAMOND_TINGE_MILKY_OPTIONS,
    _DIAMOND_LOCATION_OPTIONS,
    _DIAMOND_FANCY_COLOR_INTENSITY_OPTIONS,
    _DIAMOND_FANCY_COLOR_OVERTONE_OPTIONS,
    _DIAMOND_FANCY_COLOR_OPTIONS
} from "../../../common/constant";
import moment from "moment";
import { showCustomDialog } from "../../../application/action/app_action";
import ApiEngine from "../../../common/api-engine";
import CustomOptionSelect from '../../../components/custom-option-select';
import { useNavigate } from "react-router-dom";
import CustomInput from "../../../components/custom-input";
import { stringIsNullOrEmpty } from "common/util";

/// <summary>
/// Author: CK
/// </summary>
export default (props) => {
    var _dispatch = useDispatch();
    const _navigate = useNavigate();
    const { t } = useTranslation();
    const _USER_DATA = useSelector((state) => state.authState.userData);
    const [selectedDiamondShapes, setSelectedDiamondShapes] = useState([]);
    const [selectedDiamondColors, setSelectedDiamondColors] = useState([]);
    const [selectedDiamondClarity, setSelectedDiamondClarity] = useState([]);
    const [selectedDiamondEyeCleans, setSelectedDiamondEyeCleans] = useState([]);
    const [selectedDiamondShades, setSelectedDiamondShades] = useState([]);
    const [selectedDiamondMilkys, setSelectedDiamondMilkys] = useState([]);
    const [selectedDiamondCuts, setSelectedDiamondCuts] = useState([]);
    const [selectedDiamondPolishs, setSelectedDiamondPolishs] = useState([]);
    const [selectedDiamondSymmetrys, setSelectedDiamondSymmetrys] = useState([]);
    const [selectedDiamondFluorescenses, setSelectedDiamondFluorescenses] = useState([]);
    const [selectedDiamondLocations, setSelectedDiamondLocations] = useState([]);
    const [selectedDiamondLabs, setSelectedDiamondLabs] = useState([]);
    const [minCaratValue, setMinCaratValue] = useState(0);
    const [maxCaratValue, setMaxCaratValue] = useState(10);
    const [certificate, setCertificate] = useState("");
    const [selectedDiamondType, setSelectedDiamondType] = useState(props?.diamondType == DiamondType._NATURAL ? DiamondType._NATURAL : DiamondType._LAB_GROWN);
    const [selectedDiamondColorType, setSelectedDiamondColorType] = useState(props?.diamondColorType == DiamondColorType._WHITE ? DiamondColorType._WHITE : DiamondColorType._FANCY_COLOR);
    const [selectedDiamondFancyColors, setSelectedDiamondFancyColors] = useState([]);
    const [selectedDiamondFancyColorOvertones, setSelectedDiamondFancyColorOvertones] = useState([]);
    const [selectedDiamondFancyColorIntensities, setSelectedDiamondFancyColorIntensities] = useState([]);

    useEffect(() => {
        if (props?.diamondType != undefined) {
            setSelectedDiamondType(props?.diamondType == DiamondType._NATURAL ? DiamondType._NATURAL : DiamondType._LAB_GROWN);
        }

        if (props?.diamondColorType != undefined) {
            setSelectedDiamondColorType(props?.diamondColorType == DiamondColorType._WHITE ? DiamondColorType._WHITE : DiamondColorType._FANCY_COLOR);
        }
    }, [props]);

    /// <summary>
    /// Author: CK
    /// </summary>
    const searchDiamonds = () => {
        try {
            let delimiter = ',';
            let webUrl = WebUrl._INVENTORY_DIAMONDS;
            webUrl += `?minct=${Number(minCaratValue)}&maxct=${Number(maxCaratValue)}`;
            webUrl += `&typeId=${selectedDiamondType}`;

            if (selectedDiamondShapes.length > 0) {
                webUrl += `&shape=${selectedDiamondShapes.join(delimiter)}`;
            }

            if (selectedDiamondColorType == DiamondColorType._FANCY_COLOR) {
                webUrl += `&isFancyColor=true`;

                if (selectedDiamondFancyColors.length > 0) {
                    webUrl += `&fancyColor=${selectedDiamondFancyColors.join(delimiter)}`;
                }

                if (selectedDiamondFancyColorIntensities.length > 0) {
                    webUrl += `&fancyColorIntensity=${selectedDiamondFancyColorIntensities.join(delimiter)}`;
                }

                if (selectedDiamondFancyColorOvertones.length > 0) {
                    webUrl += `&fancyColorOvertone=${selectedDiamondFancyColorOvertones.join(delimiter)}`;
                }
            }
            else {
                webUrl += `&isFancyColor=false`;

                if (selectedDiamondColors.length > 0) {
                    webUrl += `&color=${selectedDiamondColors.join(delimiter)}`;
                }
            }

            if (selectedDiamondClarity.length > 0) {
                webUrl += `&clarity=${selectedDiamondClarity.join(delimiter)}`;
            }

            if (selectedDiamondEyeCleans.length > 0) {
                webUrl += `&eyeclean=${selectedDiamondEyeCleans.join(delimiter)}`;
            }

            if (selectedDiamondShades.length > 0) {
                webUrl += `&shade=${selectedDiamondShades.join(delimiter)}`;
            }

            if (selectedDiamondMilkys.length > 0) {
                webUrl += `&milky=${selectedDiamondMilkys.join(delimiter)}`;
            }

            if (selectedDiamondCuts.length > 0) {
                webUrl += `&cut=${selectedDiamondCuts.join(delimiter)}`;
            }

            if (selectedDiamondPolishs.length > 0) {
                webUrl += `&polish=${selectedDiamondPolishs.join(delimiter)}`;
            }

            if (selectedDiamondSymmetrys.length > 0) {
                webUrl += `&symmetry=${selectedDiamondSymmetrys.join(delimiter)}`;
            }

            if (selectedDiamondFluorescenses.length > 0) {
                webUrl += `&fluo=${selectedDiamondFluorescenses.join(delimiter)}`;
            }

            if (selectedDiamondLocations.length > 0) {
                webUrl += `&location=${selectedDiamondLocations.join(delimiter)}`;
            }

            if (selectedDiamondLabs.length > 0) {
                webUrl += `&lab=${selectedDiamondLabs.join(delimiter)}`;
            }

            if (!stringIsNullOrEmpty(certificate)) {
                webUrl += `&certificate=${certificate}`;
            }

            _navigate(webUrl, true);
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    /// <summary>
    /// Author: CK
    /// </summary>
    const reset = () => {
        try {
            setSelectedDiamondShapes([]);
            setSelectedDiamondColors([]);
            setSelectedDiamondClarity([]);
            setSelectedDiamondEyeCleans([]);
            setSelectedDiamondShades([]);
            setSelectedDiamondMilkys([]);
            setSelectedDiamondCuts([]);
            setSelectedDiamondPolishs([]);
            setSelectedDiamondSymmetrys([]);
            setSelectedDiamondFluorescenses([]);
            setSelectedDiamondLocations([]);
            setSelectedDiamondLabs([]);
            setMinCaratValue(0);
            setMaxCaratValue(10);
            setCertificate("");
        }
        catch (err) {
            _dispatch(
                showCustomDialog({
                    success: false,
                    content: err
                })
            );
        }
    };

    return (
        <Box pt={{ base: "80px", md: "60px", xl: "60px" }}>
            <Card
                justifyContent='center'
                align='center'
                direction='column'
                w='100%'
                mb='0px'>
                <CustomOptionSelect
                    btnFontSize={'11px'}
                    width={'75px'}
                    height={'75px'}
                    label={t('SHAPE')}
                    options={_DIAMOND_SHAPE_OPTIONS}
                    onChange={setSelectedDiamondShapes}
                    selectedValue={selectedDiamondShapes}
                />
                <Divider mb={'15px'} />
                <Flex flexDirection={'row'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t('CARAT')}</Text>
                    {/* <RangeSlider
                            aria-label={["min", "max"]}
                            defaultValue={[0, 1]}
                            step={0.01}
                            color="brand.900"
                            min={0}
                            max={10}
                            onChange={(val) => setCaratValue(val)}
                        >
                            <RangeSliderTrack>
                                <RangeSliderFilledTrack bgColor={'brand.900'} />
                            </RangeSliderTrack>
                            <RangeSliderThumb index={0} />
                            <RangeSliderThumb index={1} />
                        </RangeSlider> */}
                    <Flex flexDirection={'row'} justifyContent={'space-between'}>
                        <CustomInput
                            style={{ marginBottom: 0 }}
                            id={"minCaratValue"}
                            defaultValue={minCaratValue}
                            onChange={(value) => setMinCaratValue(value.target.value)}
                            placeHolder={"FROM"}
                            inputType={"number"}
                            min={0}
                            max={100}
                            width={"150px"}
                            marginRight={"15px"}
                        />
                        <CustomInput
                            style={{ marginBottom: 0 }}
                            id={"maxCaratValue"}
                            defaultValue={maxCaratValue}
                            onChange={(value) => setMaxCaratValue(value.target.value)}
                            placeHolder={"TO"}
                            inputType={"number"}
                            min={0}
                            max={100}
                            width={"150px"}
                        />
                    </Flex>
                </Flex>
                <Divider mb={'15px'} />
                <Flex flexDirection={'row'} alignItems={'center'}>
                    <Flex flexDirection={'column'} mb={'15px'}>
                        <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t("COLOR")}</Text>
                        <Flex flexDirection={'row'} mb={'15px'} alignItems={'center'}>
                            <Link
                                onClick={() => setSelectedDiamondColorType(DiamondColorType._WHITE)}
                                color={selectedDiamondColorType == DiamondColorType._WHITE ? 'gray' : 'brand.950'} fontWeight={selectedDiamondColorType == DiamondColorType._WHITE ? '300' : '800'} mr={'2.5px'} fontSize={'13px'}
                            >
                                {t("WHITE")}
                            </Link>
                            /
                            <Link
                                onClick={() => setSelectedDiamondColorType(DiamondColorType._FANCY_COLOR)}
                                color={selectedDiamondColorType == DiamondColorType._FANCY_COLOR ? 'gray' : 'brand.950'} fontWeight={selectedDiamondColorType == DiamondColorType._FANCY_COLOR ? '300' : '800'} ml={'2.5px'} fontSize={'13px'}
                            >
                                {t("FANCY")}
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex flexDirection={'row'} alignItems={'center'} justifyContent={'flex-start'} className="option-select" flexWrap={'wrap'}>
                        {
                            selectedDiamondColorType == DiamondColorType._WHITE ?
                                <CustomOptionSelect
                                    options={_DIAMOND_COLOR_OPTIONS}
                                    onChange={setSelectedDiamondColors}
                                    selectedValue={selectedDiamondColors}
                                />
                                :
                                <Flex flexDir={'column'} alignItems={'flex-start'}>
                                    <Text fontWeight={800}>{t("INTENSITY")}</Text>
                                    <CustomOptionSelect
                                        options={_DIAMOND_FANCY_COLOR_INTENSITY_OPTIONS}
                                        onChange={setSelectedDiamondFancyColorIntensities}
                                        selectedValue={selectedDiamondFancyColorIntensities}
                                    />
                                    <Text fontWeight={800}>{t("SUB_COLOR")}</Text>
                                    <CustomOptionSelect
                                        options={_DIAMOND_FANCY_COLOR_OVERTONE_OPTIONS}
                                        onChange={setSelectedDiamondFancyColorOvertones}
                                        selectedValue={selectedDiamondFancyColorOvertones}
                                    />
                                    <Text fontWeight={800}>{t("DOMINANT_COLOR")}</Text>
                                    <CustomOptionSelect
                                        options={_DIAMOND_FANCY_COLOR_OPTIONS}
                                        onChange={setSelectedDiamondFancyColors}
                                        selectedValue={selectedDiamondFancyColors}
                                    />
                                </Flex>
                        }

                    </Flex>
                </Flex>
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('CLARITY')}
                    options={_DIAMOND_CLARITY_OPTIONS}
                    onChange={setSelectedDiamondClarity}
                    selectedValue={selectedDiamondClarity}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('EYE_CLEAN')}
                    options={_DIAMOND_TINGE_EYE_CLEAN_OPTIONS}
                    onChange={setSelectedDiamondEyeCleans}
                    selectedValue={selectedDiamondEyeCleans}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('SHADE')}
                    options={_DIAMOND_TINGE_SHADE_OPTIONS}
                    onChange={setSelectedDiamondShades}
                    selectedValue={selectedDiamondShades}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('MILKY')}
                    options={_DIAMOND_TINGE_MILKY_OPTIONS}
                    onChange={setSelectedDiamondMilkys}
                    selectedValue={selectedDiamondMilkys}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('FINISH')}
                    description={t('CUT')}
                    options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                    onChange={setSelectedDiamondCuts}
                    selectedValue={selectedDiamondCuts}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('FINISH')}
                    description={t('POLISH')}
                    options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                    onChange={setSelectedDiamondPolishs}
                    selectedValue={selectedDiamondPolishs}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('FINISH')}
                    description={t('SYMMETRY')}
                    options={_DIAMOND_FINISH_LEVEL_OPTIONS}
                    onChange={setSelectedDiamondSymmetrys}
                    selectedValue={selectedDiamondSymmetrys}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('FLUORESENCE_INTENSITY')}
                    options={_DIAMOND_FLUORESCENSE_OPTIONS}
                    onChange={setSelectedDiamondFluorescenses}
                    selectedValue={selectedDiamondFluorescenses}
                />
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('LAB')}
                    options={_DIAMOND_LAB_OPTIONS}
                    onChange={setSelectedDiamondLabs}
                    selectedValue={selectedDiamondLabs}
                />
                <Divider mb={'15px'} />
                <Flex flexDirection={'row'} mb={'15px'}>
                    <Text textAlign={'left'} width={'150px'} marginRight={'10px'} fontWeight={'bold'}>{t('CERTIFICATE')}</Text>
                    <Flex flexDirection={'row'} justifyContent={'space-between'}>
                        <CustomInput
                            style={{ marginBottom: 0 }}
                            id={"certificate"}
                            defaultValue={minCaratValue}
                            onChange={(value) => setCertificate(value.target.value)}
                            placeHolder={"CERTIFICATE"}
                            inputType={"text"}
                            width={"150px"}
                        />
                    </Flex>
                </Flex>
                <Divider mb={'15px'} />
                <CustomOptionSelect
                    label={t('LOCATION')}
                    options={_DIAMOND_LOCATION_OPTIONS}
                    onChange={setSelectedDiamondLocations}
                    selectedValue={selectedDiamondLocations}
                />
                <Flex justifyContent={'center'}>
                    <Button
                        fontSize="md"
                        variant="brand"
                        fontWeight="500"
                        mb="24px"
                        type="button"
                        margin={'5px'}
                        onClick={() => {
                            searchDiamonds();
                        }}
                    >
                        {t('FILTER')}
                    </Button>
                    <Button
                        fontSize="md"
                        variant="secondaryGray"
                        fontWeight="500"
                        mb="24px"
                        type="button"
                        margin={'5px'}
                        onClick={() => {
                            reset();
                        }}
                    >
                        {t('RESET')}
                    </Button>
                </Flex>
            </Card>
        </Box >
    )
}